header {
  font-size: 42px;
  line-height: 1.23;
  font-weight: 600;
}

p {
  font-size: 20px;
  line-height: 1.55;
  font-weight: 300;
}

.text-align-center {
  text-align: center;
}