.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-col-reverse {
  display: flex;
  flex-direction: column-reverse;
}

.flex-j-between {
  justify-content: space-between;
}

.flex-j-center {
  justify-content: center;
}

.flex-j-around {
  justify-content: space-around;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-no-shrink {
  flex-shrink: 0;
}

.flex-push-right {
  margin-left: auto;
}

.flex-push-down {
  margin-top: auto;
}

.flex-push-center {
  margin-left: auto;
  margin-right: auto;
}

.flex-align-self-end {
  align-self: flex-end;
}

.flex-align-self-start {
  align-self: flex-start;
}

.flex-align-start {
  align-items: flex-start;
}

.flex-align-end {
  align-items: flex-end;
}

.flex-align-center {
  align-items: center;
}