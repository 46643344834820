@import "src/css/colors";

.App {
  margin: auto;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 14px;
  color: $color_black;
}

.content {
  width: 1200px;
}

.header {
  background: black;
  width: 100%;
  //position: fixed;

  img {
    max-height: 100%;
    max-width: 100%;
  }

  .content {
    max-height: 100px;

    .link-block {
      color: white;
      font-size: 16px;
      font-weight: bold;
      text-decoration: none;
    }
  }
}

.footer {
  background-color: black;
  color: white;

  span {
    font-size: 16px;
    font-weight: 600;
  }
}