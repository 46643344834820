@import "src/css/colors";

.first-block {
  background: url("../../static/zvezdnoe-nebo.jpg") no-repeat;
  background-size: cover;
  height: 600px;
  color: white;

  .name {
    font-size: 72px;
    line-height: 1.17;
    font-weight: 600;
    text-align: center;
  }

  .slogan {
    font-size: 22px;
    line-height: 1.55;
    font-weight: 300;
  }
}

.second-block {
  background-color: $color_background_grey;

  img {
    width: 220px;
  }
}

.third-block {
  .plus {
    img {
      width: 55px;
    }
    header {
      font-size: 20px;
    }
    span {
      font-size: 18px;
    }
  }
}

.fourth-block {
  background-color: $color_background_grey;

  .service {
    img {
      width: 350px;
    }
    header {
      font-size: 24px;
    }
    span {
      font-size: 16px;
    }
  }
}

.fifth-block {
  .partner {
    .img-block {
      height: 200px;
    }
    img {
      width: 170px;
    }
    header {
      font-size: 18px;
    }
  }
}