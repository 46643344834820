.width-100 {
  width: 100%;
}

.width-80 {
  width: 80%;
}

.width-50 {
  width: 50%;
}

.width-33 {
  width: 33%;
}

.width-25 {
  width: 25%;
}

.width-20 {
  width: 20%;
}

.height-100 {
  height: 100%;
}