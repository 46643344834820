$sizes: (
        xs: 4px,
        s: 8px,
        ss: 12px,
        sm: 14px,
        m: 16px,
        m-20: 20px,
        mm: 24px,
        l: 32px,
        ll: 40px,
        lll: 48px,
        xl: 50px,
        xxl: 56px,
);
$directions: (
        top: t,
        right: r,
        bottom: b,
        left: l,
);

@each $size, $px in $sizes {
  @each $dir, $d in $directions {
    .margin-#{$d}-#{$size} {
      margin-#{$dir}: $px;
    }
    .margin-all-#{$d}-#{$size} > *:not(:last-child) {
      margin-#{$dir}: $px;
    }
  }
}

.margin-no {
  margin: 0 !important;
}

.margin-no-vertical {
  margin: 0 auto !important;
}

// region placeholders
.margin-t-xs,
.margin-all-t-xs,
.margin-r-xs,
.margin-all-r-xs,
.margin-b-xs,
.margin-all-b-xs,
.margin-l-xs,
.margin-all-l-xs,
.margin-t-s,
.margin-all-t-s,
.margin-r-s,
.margin-all-r-s,
.margin-b-s,
.margin-all-b-s,
.margin-l-s,
.margin-all-l-s,
.margin-t-ss,
.margin-all-t-ss,
.margin-r-ss,
.margin-all-r-ss,
.margin-b-ss,
.margin-all-b-ss,
.margin-l-ss,
.margin-all-l-ss,
.margin-t-sm,
.margin-all-t-sm,
.margin-r-sm,
.margin-all-r-sm,
.margin-b-sm,
.margin-all-b-sm,
.margin-l-sm,
.margin-all-l-sm,
.margin-t-m,
.margin-all-t-m,
.margin-r-m,
.margin-all-r-m,
.margin-b-m,
.margin-all-b-m,
.margin-l-m,
.margin-all-l-m,
.margin-t-m-20,
.margin-all-t-m-20,
.margin-r-m-20,
.margin-all-r-m-20,
.margin-b-m-20,
.margin-all-b-m-20,
.margin-l-m-20,
.margin-all-l-m-20,
.margin-t-mm,
.margin-all-t-mm,
.margin-r-mm,
.margin-all-r-mm,
.margin-b-mm,
.margin-all-b-mm,
.margin-l-mm,
.margin-all-l-mm,
.margin-t-l,
.margin-all-t-l,
.margin-r-l,
.margin-all-r-l,
.margin-b-l,
.margin-all-b-l,
.margin-l-l,
.margin-all-l-l,
.margin-t-ll,
.margin-all-t-ll,
.margin-r-ll,
.margin-all-r-ll,
.margin-b-ll,
.margin-all-b-ll,
.margin-l-ll,
.margin-all-l-ll,
.margin-t-lll,
.margin-all-t-lll,
.margin-r-lll,
.margin-all-r-lll,
.margin-b-lll,
.margin-all-b-lll,
.margin-l-lll,
.margin-all-l-lll,
.margin-t-xl,
.margin-all-t-xl,
.margin-r-xl,
.margin-all-r-xl,
.margin-b-xl,
.margin-all-b-xl,
.margin-l-xl,
.margin-all-l-xl,
.margin-t-xxl,
.margin-all-t-xxl,
.margin-r-xxl,
.margin-all-r-xxl,
.margin-b-xxl,
.margin-all-b-xxl,
.margin-l-xxl,
.margin-all-l-xxl {
}
